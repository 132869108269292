
<div class="top-row">

  <mat-form-field>

    <mat-label>Název</mat-label>

    <input matInput type="text"
           [(ngModel)]="ssv.selection.name"
           (keyup.enter)="onSave()"
           [disabled]="manage">


    <button mat-icon-button matSuffix [disabled]="!canExec" (click)="onExec()">
      <mat-icon>{{execIcon}}</mat-icon>
    </button>

    <mat-hint align="end">{{execHint}}</mat-hint>

  </mat-form-field>

  <button mat-icon-button [matMenuTriggerFor]="listMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

</div>

  <mat-menu #listMenu="matMenu">

      <button mat-menu-item (click)="manage = !manage">
        <mat-icon>{{manage ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span>Práce se seznamy</span>
      </button>

      <mat-divider></mat-divider>

      <button mat-menu-item
              [disabled]="!manage || lists.selectedOptions.selected.length == 0"
              (click)="onDelete()">
        <span>Smazat vybrané seznamy</span>
      </button>

      <button mat-menu-item
              [disabled]="!manage"
              (click)="onImport()">
        <span>Načíst seznamy z minulé verze</span>
      </button>


  </mat-menu>

@if (manage) {

<mat-selection-list #lists [multiple]="true" >

  @for (list of userLists?.my; track $index) {

  <mat-list-option [value]="list">
    <span class="list-name">
      {{list.name}}
      @if (isCurrent(list)) {
        <span class="list-current"> - {{msg.state.current}}</span>
      }
    </span>

  </mat-list-option>

 }

</mat-selection-list>

}
@else {

<mat-selection-list #lists [multiple]="false" >

  @for (list of userLists?.my; track $index) {

  <mat-list-option [value]="list">
    <span class="list-name">
      {{list.name}}
      @if (isCurrent(list)) {
        <span class="list-current"> - {{msg.state.current}}</span>
      }
    </span>

  </mat-list-option>

 }

</mat-selection-list>

}

